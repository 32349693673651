.App {
  text-align: center;
  background-color: black;
}

.navbar {
  text-align: center;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
}

.option-list {
  list-style-type: none;
  padding: 0;
  text-align: start;
}

#navbar {
  width: 100vw;
  height: 10vh;
  height: fit-content;
  text-align: center;
}

#navbar-image {
  width: 10%;
  padding: 2%;
}

#submit-button {
  background-color: green;
  color: white;
  font-size: 20px;
  font-weight: 400;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0px;
  cursor: pointer;
}

.App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: black;
}

.chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#result {
  height: 90vh;
  border-left: 5px solid white;
}
